import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { asPromise, transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { Observable, ReplaySubject, fromEvent, mapTo, merge, shareReplay, startWith, tap } from 'rxjs';

export type BeforeInstallPromptEvent = {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<UserChoice>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
  preventDefault(): void;
};

type UserChoice = {
  outcome: 'accepted' | 'dismissed';
  platform: string;
};

export class PwaService {
  public beforeInstallPromptEvent$: ReplaySubject<BeforeInstallPromptEvent> =
    new ReplaySubject<BeforeInstallPromptEvent>(1);

  private _getIsAppInstalledShareRef$!: Observable<boolean | undefined>;

  public init(): void {
    fromEvent<BeforeInstallPromptEvent>(window, 'beforeinstallprompt')
      .pipe(tap((e: BeforeInstallPromptEvent) => e.preventDefault()))
      .subscribe(transferRxNext(this.beforeInstallPromptEvent$));
  }

  public isAppInstalled(): Observable<boolean | undefined> {
    return (this._getIsAppInstalledShareRef$ ??= merge(
      this.beforeInstallPromptEvent$.pipe(mapTo(false)),
      fromEvent(window, 'appinstalled').pipe(mapTo(true))
    ).pipe(startWith(undefined), shareReplay(1)));
  }

  public async install(): Promise<void> {
    const beforeInstallEvent: BeforeInstallPromptEvent = await asPromise(this.beforeInstallPromptEvent$);
    beforeInstallEvent.prompt();
  }
}

export function providePwaService(): PwaService {
  return provideSingleton(PwaService, () => new PwaService());
}
